import { connect } from "react-redux";
import * as authActions from "../redux/auth";
import * as appActions from "../redux/app"
import { TEXTS } from "../helpers/statics";
import Cookies from "js-cookie";
import Router from "next/router";
import { getLocale, getPersonalDisc } from "../helpers/stateFunctions";
import Link from "next/link";
import React from "react";
class SBnewFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFooterSections: [],
    };
  }
  state = {
    openFooterSections: [],
    prefLocale: "tr",
  };

  changeLanguage() {
    let currentLocale = getLocale(this.props);
    let preferredLocale = currentLocale == "tr" ? "en" : "tr"; //tr baskin hataya karsi

    //anonimse
    if (!this.props.user.user) {
      Cookies.set("preferredLocale", preferredLocale);
      Cookies.remove("popupseen3")
      location.reload();
      return;
    }
    //user varsa
    let params = { preferredLocale };
    this.props.updateProfile(params, (resp) => {
      Cookies.set("preferredLocale", preferredLocale);
      this.props.setPopupContent(null)
      this.props.fetchWebsiteBanner()
      this.props.setShowPopup(true)
    });
  }

  onFooterSectionClicked(sectionName) {
    let openFooterSections = [...this.state.openFooterSections];
    let idx = openFooterSections.indexOf(sectionName);
    if (idx < 0) {
      console.log("1");
      openFooterSections.push(sectionName);
    } else {
      console.log("2");
      openFooterSections.splice(idx, 1);
    }
    this.setState({ openFooterSections });
  }

  isSelectedSection(sectionName) {
    return this.state.openFooterSections?.includes(sectionName);
  }

  componentDidMount() {
    let locale = getLocale({ ...this.props });
    this.setState({ prefLocale: locale });
  }

  render() {
    let _state = { ...this.props };
    let locale = getLocale(_state);
    let personaldiscLink = `https://content.sbuxtr.com/static/docs/${locale}/personaldisc.html`;

    return (
      <>
        <div className="sb-new-footer">
          <div className="foot-top">
            <div className="menu-list">
              <div className="menu-item">
                <div
                  onClick={() => {
                    this.onFooterSectionClicked("about");
                  }}
                  className="menu-head"
                >
                  <div className="left">
                    <span className="title">
                      {locale == "tr" ? "Hakkımızda" : "About Us"}
                    </span>
                  </div>
                  <div className="right">
                    <div
                      className={`icon-wrapper ${this.isSelectedSection("about") ? "active-arrow" : ""
                        }`}
                    >
                      <span
                        className="icon button-icon"
                        data-component="icon"
                        data-icon="caret-down"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          className="valign-middle"
                          aria-hidden="true"
                        >
                          <path d="M11.96 15.5a.761.761 0 0 1-.546-.232l-5.188-5.33a.81.81 0 0 1 0-1.12c.3-.31.79-.31 1.093 0l4.64 4.767 4.723-4.853c.3-.31.79-.31 1.09 0 .303.31.303.812.002 1.122l-5.27 5.414a.763.763 0 0 1-.546.232" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                {this.isSelectedSection("about") && (
                  <div className="sub-menu" style={{ display: "block" }}>
                    <Link href="/about-us/our-heritage">
                      <a className="sub-item text-noUnderline">
                        {TEXTS.history[locale]}
                      </a>
                    </Link>
                    <Link href="/about-us/company-information">
                      <a
                        href="/about-us/company-information"
                        className="sub-item text-noUnderline"
                      >
                        {TEXTS.the_company[locale]}
                      </a>
                    </Link>
                    <Link href="/coffeehouse">
                      <a
                        href="/coffeehouse"
                        className="sub-item text-noUnderline"
                      >
                        {TEXTS.store[locale]}
                      </a>
                    </Link>
                    <Link href="/starbucks-haberler">
                      <a
                        href="/starbucks-haberler"
                        className="sub-item text-noUnderline"
                      >
                        {TEXTS.stories[locale]}
                      </a>
                    </Link>
                    <Link href="/cup-design-call">
                      <a
                        href="/cup-design-call"
                        className="sub-item text-noUnderline"
                      >
                        {TEXTS.landing_starbucks_page[locale]}
                      </a>
                    </Link>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div
                  onClick={() => {
                    this.onFooterSectionClicked("coffee");
                  }}
                  className="menu-head"
                >
                  <div className="left">
                    <span className="title">
                      {locale == "tr" ? "Kahve" : "Coffee"}
                    </span>
                  </div>
                  <div className="right">
                    <div
                      className={`icon-wrapper ${this.isSelectedSection("coffee") ? "active-arrow" : ""
                        }`}
                    >
                      <span
                        className="icon button-icon"
                        data-component="icon"
                        data-icon="caret-down"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          className="valign-middle"
                          aria-hidden="true"
                        >
                          <path d="M11.96 15.5a.761.761 0 0 1-.546-.232l-5.188-5.33a.81.81 0 0 1 0-1.12c.3-.31.79-.31 1.093 0l4.64 4.767 4.723-4.853c.3-.31.79-.31 1.09 0 .303.31.303.812.002 1.122l-5.27 5.414a.763.763 0 0 1-.546.232" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                {this.isSelectedSection("coffee") && (
                  <div className="sub-menu" style={{ display: "block" }}>
                    <Link href="/coffee/reserve">
                      <a className="sub-item text-noUnderline">
                        Starbucks Reserve
                      </a>
                    </Link>
                    <Link href="/coffee/supply-roastry-blending">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Kahve Tedariği & Kavurma & Harmanlama"
                          : "Coffee Sourcing, Roasting, and Blending"}
                      </a>
                    </Link>
                    <Link href="/coffee/roast">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Kavurma Profiline Göre Kahveler"
                          : "Coffees by Roasting Profiles"}
                      </a>
                    </Link>
                    <Link href="/coffee/how-to-brew">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Nasıl Mükemmel Kahve Hazırlanır"
                          : "Making the Perfect Coffee at Home"}
                      </a>
                    </Link>
                    <Link href="/coffee/faq">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr" ? "Kahve SSS" : "Coffee FAQs"}
                      </a>
                    </Link>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div
                  onClick={() => {
                    this.onFooterSectionClicked("social");
                  }}
                  className="menu-head"
                >
                  <div className="left">
                    <span className="title">
                      {locale == "tr"
                        ? "Sosyal Sorumluluk"
                        : "Social Responsibility"}
                    </span>
                  </div>
                  <div className="right">
                    <div
                      className={`icon-wrapper ${this.isSelectedSection("social") ? "active-arrow" : ""
                        }`}
                    >
                      <span
                        className="icon button-icon"
                        data-component="icon"
                        data-icon="caret-down"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          className="valign-middle"
                          aria-hidden="true"
                        >
                          <path d="M11.96 15.5a.761.761 0 0 1-.546-.232l-5.188-5.33a.81.81 0 0 1 0-1.12c.3-.31.79-.31 1.093 0l4.64 4.767 4.723-4.853c.3-.31.79-.31 1.09 0 .303.31.303.812.002 1.122l-5.27 5.414a.763.763 0 0 1-.546.232" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                {this.isSelectedSection("social") && (
                  <div className="sub-menu" style={{ display: "block" }}>
                    <Link href="/responsibility/sourcing">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr" ? "Etik ticaret" : "Ethical Sourcing"}
                      </a>
                    </Link>
                    <Link href="/responsibility/community">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Topluma katkı"
                          : "Contributing to communities"}
                      </a>
                    </Link>
                    <Link href="/responsibility/yardim-calismalari">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Yardım Çalışmaları"
                          : "Relief Efforts"}
                      </a>
                    </Link>
                    <Link href="/responsibility/environment">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr" ? "Çevre" : "Environment"}
                      </a>
                    </Link>
                    <Link href="/responsibility/diversity">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Çeşitlilik"
                          : "Diversity at Starbucks"}
                      </a>
                    </Link>
                    <Link href="/responsibility/sustainability">
                      <a className="sub-item text-noUnderline">
                        {locale == "tr"
                          ? "Sürdürülebilirlik"
                          : "Sustainability"}
                      </a>
                    </Link>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div
                  onClick={() => {
                    this.onFooterSectionClicked("delivers");
                  }}
                  className="menu-head"
                >
                  <div className="left">
                    <a href="/delivers" style={{ textDecoration: "none" }}>
                      <span className="title">Starbucks Delivers</span>
                    </a>
                  </div>
                  <div className="right">
                    <div
                      className={`icon-wrapper ${this.isSelectedSection("delivers") ? "active-arrow" : ""
                        }`}
                    >
                      <span
                        className="icon button-icon"
                        data-component="icon"
                        data-icon="caret-down"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          className="valign-middle"
                          aria-hidden="true"
                        >
                          <path d="M11.96 15.5a.761.761 0 0 1-.546-.232l-5.188-5.33a.81.81 0 0 1 0-1.12c.3-.31.79-.31 1.093 0l4.64 4.767 4.723-4.853c.3-.31.79-.31 1.09 0 .303.31.303.812.002 1.122l-5.27 5.414a.763.763 0 0 1-.546.232" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                {this.isSelectedSection("delivers") && (
                  <div className="sub-menu" style={{ display: "block" }}>
                    <Link href="/delivers/delivery-yemeksepeti">
                      <a
                        href="/delivers/delivery-yemeksepeti"
                        className="sub-item text-noUnderline"
                      >
                        Yemeksepeti
                      </a>
                    </Link>
                    <Link href="/delivers/delivery-getir">
                      <a
                        href="/delivers/delivery-getir"
                        className="sub-item text-noUnderline"
                      >
                        GetirYemek
                      </a>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="ui-divider" />
          <div className="foot-bottom">
            <ul
              className="flex sb-footer-iconMarginAdjust"
              style={{ marginBottom: "15px" }}
            >
              <li className="sm-mr1">
                <a
                  aria-label="facebook"
                  className="sb-iconButton relative inline-block"
                  href="https://www.facebook.com/StarbucksTurkiye"
                  style={{ minWidth: "44px", minHeight: "44px" }}
                  target={"_blank"}
                >
                  <svg
                    aria-hidden="true"
                    className="valign-middle absoluteCenter"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      width: "32px",
                      height: "32px",
                      overflow: "visible",
                      fill: "currentColor",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.9997802,0 C18.6169827,0 24,5.38345699 24,12.0002198 C24,18.6169827 18.6169827,24 11.9997802,24 C5.38301731,24 0,18.6169827 0,12.0002198 C0,5.38345699 5.38301731,0 11.9997802,0 Z M15.4043684,4.8 L12.7777895,4.8 C11.2364211,4.8 9.98636842,5.96104896 9.98636842,7.39365487 L9.98636842,9.44903723 L8.59515789,9.44903723 C8.48763158,9.44903723 8.4,9.53002017 8.4,9.6303686 L8.4,11.7623327 C8.4,11.862241 8.48763158,11.9432239 8.59515789,11.9432239 L9.98636842,11.9432239 L9.98636842,19.0186686 C9.98636842,19.1190171 10.074,19.2 10.1815263,19.2 L12.9639474,19.2 C13.0719474,19.2 13.1591053,19.1190171 13.1591053,19.0186686 L13.1591053,11.9432239 L15.1765263,11.9432239 C15.2778947,11.9432239 15.3626842,11.8714836 15.3707368,11.7772969 L15.5649474,9.64533284 C15.5748947,9.53970292 15.4844211,9.44903723 15.3702632,9.44903723 L13.1591053,9.44903723 L13.1591053,7.93632863 C13.1591053,7.58202824 13.4688947,7.29462681 13.8502105,7.29462681 L15.4043684,7.29462681 C15.5123684,7.29462681 15.6,7.21320374 15.6,7.11329543 L15.6,4.98133138 C15.6,4.88142307 15.5123684,4.8 15.4043684,4.8 Z" />
                    <circle
                      className="sb-icon-hover"
                      cx="50%"
                      cy="50%"
                      fill="transparent"
                      r="75%"
                    />
                  </svg>
                </a>
              </li>
              <li className="sm-mr1">
                <a
                  aria-label="instagram"
                  className="sb-iconButton relative inline-block"
                  href="https://www.instagram.com/starbucks_tr/"
                  style={{ minWidth: "44px", minHeight: "44px" }}
                  target={"_blank"}
                >
                  <svg
                    aria-hidden="true"
                    className="valign-middle absoluteCenter"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      width: "32px",
                      height: "32px",
                      overflow: "visible",
                      fill: "currentColor",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.9997802,0 C18.6169827,0 24,5.38345699 24,12.0002198 C24,18.6169827 18.6169827,24 11.9997802,24 C5.38301731,24 0,18.6169827 0,12.0002198 C0,5.38345699 5.38301731,0 11.9997802,0 Z M15,5 L9,5 C6.790861,5 5,6.790861 5,9 L5,9 L5,15 C5,17.209139 6.790861,19 9,19 L9,19 L15,19 C17.209139,19 19,17.209139 19,15 L19,15 L19,9 C19,6.790861 17.209139,5 15,5 L15,5 Z M14.7647059,6.23529412 C16.4215601,6.23529412 17.7647059,7.57843987 17.7647059,9.23529412 L17.7647059,9.23529412 L17.7647059,14.7647059 L17.7596132,14.9409787 C17.6683668,16.5157859 16.3623868,17.7647059 14.7647059,17.7647059 L14.7647059,17.7647059 L9.23529412,17.7647059 L9.05902133,17.7596132 C7.48421408,17.6683668 6.23529412,16.3623868 6.23529412,14.7647059 L6.23529412,14.7647059 L6.23529412,9.23529412 L6.24038681,9.05902133 C6.33163324,7.48421408 7.63761323,6.23529412 9.23529412,6.23529412 L9.23529412,6.23529412 Z M11.7941176,8.70588235 C9.86112102,8.70588235 8.29411765,10.2728857 8.29411765,12.2058824 C8.29411765,14.138879 9.86112102,15.7058824 11.7941176,15.7058824 C13.7271143,15.7058824 15.2941176,14.138879 15.2941176,12.2058824 C15.2941176,10.2728857 13.7271143,8.70588235 11.7941176,8.70588235 Z M11.7941176,9.94117647 C13.0448802,9.94117647 14.0588235,10.9551198 14.0588235,12.2058824 C14.0588235,13.4566449 13.0448802,14.4705882 11.7941176,14.4705882 C10.5433551,14.4705882 9.52941176,13.4566449 9.52941176,12.2058824 C9.52941176,10.9551198 10.5433551,9.94117647 11.7941176,9.94117647 Z M15.7058824,7.47058824 C15.2510596,7.47058824 14.8823529,7.83929491 14.8823529,8.29411765 C14.8823529,8.74894038 15.2510596,9.11764706 15.7058824,9.11764706 C16.1607051,9.11764706 16.5294118,8.74894038 16.5294118,8.29411765 C16.5294118,7.83929491 16.1607051,7.47058824 15.7058824,7.47058824 Z" />
                    <circle
                      className="sb-icon-hover"
                      cx="50%"
                      cy="50%"
                      fill="transparent"
                      r="75%"
                    />
                  </svg>
                </a>
              </li>
              <li className="sm-mr1">
                <a
                  aria-label="youtube"
                  className="sb-iconButton relative inline-block"
                  href="https://www.youtube.com/c/StarbucksTr"
                  style={{ minWidth: "44px", minHeight: "44px" }}
                  target={"_blank"}
                >
                  <svg
                    aria-hidden="true"
                    className="valign-middle absoluteCenter"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      width: "32px",
                      height: "32px",
                      overflow: "visible",
                      fill: "currentColor",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.9997802,0 C18.6169827,0 24,5.38345699 24,12.0002198 C24,18.6169827 18.6169827,24 11.9997802,24 C5.38301731,24 0,18.6169827 0,12.0002198 C0,5.38345699 5.38301731,0 11.9997802,0 Z M16.1700158,7 L8.82998416,7 C7.26701245,7 6,8.25308368 6,9.79887301 L6,13.201127 C6,14.7469163 7.26701245,16 8.82998416,16 L16.1700158,16 C17.7329875,16 19,14.7469163 19,13.201127 L19,9.79887301 C19,8.25308368 17.7329875,7 16.1700158,7 Z M11.3262463,9.28078768 L14.5709798,11.3486057 C14.7141036,11.4390048 14.689657,11.6696954 14.5443107,11.7571306 L11.3218015,13.7217059 C11.1822335,13.8056832 11.0555556,13.6925608 11.0555556,13.5162084 L11.0555556,9.4838152 C11.0555556,9.30499291 11.1857894,9.19236451 11.3262463,9.28078768 Z" />
                    <circle
                      className="sb-icon-hover"
                      cx="50%"
                      cy="50%"
                      fill="transparent"
                      r="75%"
                    />
                  </svg>
                </a>
              </li>
              <li className="sm-mr1">
                <a
                  aria-label="twitter"
                  className="sb-iconButton relative inline-block"
                  href="https://twitter.com/StarbucksTR"
                  style={{ minWidth: "40px", minHeight: "44px" }}
                  target={"_blank"}
                >
                  <img src="https://content.sbuxtr.com/images/twitter-icon.svg" style={{ cursor: 'pointer', width: '31px', paddingTop: '6px', margin: '0px 4px' }} />
                </a>
              </li>
              <li className="sm-mr1">
                <a
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "#212121",
                    borderRadius: "50%",
                    textAlign: "center",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                  aria-label="twitter"
                  className="sb-iconButton relative inline-block"
                  href="https://www.tiktok.com/@starbucks_turkiye"
                  target={"_blank"}
                >
                  <svg
                    aria-hidden="true"
                    className="valign-middle absoluteCenter"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      width: "20px",
                      height: "20px",
                      overflow: "visible",
                      fill: "white",
                      position: "relative",
                      top: "13px",
                      left: "11px",
                    }}
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                    <circle
                      className="sb-icon-hover"
                      cx="50%"
                      cy="50%"
                      fill="transparent"
                      r="75%"
                    />
                  </svg>
                </a>
              </li>
            </ul>

            <div className="secondary-links">
              <Link href="/privacy-policy">
                <a className="link-item text-noUnderline">
                  {TEXTS.personalDiscText[locale]}
                </a>
              </Link>
              <Link href="/ticari-iletisim-aydinlatma-metni">
                <a className="link-item text-noUnderline">
                  {TEXTS.communicationDiscText[locale]}
                </a>
              </Link>
              <Link href="/terms-of-use">
                <a className="link-item text-noUnderline">
                  {TEXTS.termsOfUse[locale]}
                </a>
              </Link>
              <span
                onClick={() => Router.push("/?efl--edit=1")}
                className="link-item"
              >
                {locale == "tr" ? "Çerez Tercihleri" : "Cookie Preferences"}
              </span>
              <Link href="/cerez-aydinlatma-metni">
                <a className="link-item text-noUnderline">
                  {locale == "tr" ? "Çerez Aydınlatma Metni" : "Cookie Information Notice"}
                </a>
              </Link>
              <Link href="/nutrition">
                <a className="link-item text-noUnderline">
                  {locale == "tr" ? "Besin Değerleri Tablosu" : "Nutrition"}
                </a>
              </Link>
              <Link href="/giftcard">
                <a className="link-item text-noUnderline">
                  {locale == "tr" ? "Gift Card" : "Gift Card"}
                </a>
              </Link>
              <Link href="/contact">
                <a className="link-item text-noUnderline">
                  {locale == "tr" ? "Bize Ulaşın" : "Contact Us"}
                </a>
              </Link>
              {/*<Link href="/help"><a className="link-item text-noUnderline">{TEXTS.help[locale]}</a></Link>*/}
              {/* <Link href="/press"><a className="link-item text-noUnderline">{locale == "tr"?"Basın":"Press"}</a></Link> */}
            </div>

            <button
              onClick={() => {
                this.changeLanguage();
              }}
              className="sb-button sb-button--default sb-button--black langBtn"
              type="button"
              style={{
                minWidth: "0px",
                minHeight: "0px",
                display: "block",
                marginBottom: 10,
              }}
            >
              {TEXTS.lang[locale]}
            </button>
          </div>
          <span style={{ color: 'white' }}>Version: 1.2.5</span>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //AUTH//
    updateProfile: (params, callback) => {
      dispatch(authActions.updateProfile(params)).then((resp) => {
        if (callback) {
          callback(resp);
        }
      });
    },
    setShowPopup: (value) => {
      dispatch(appActions.setShowPopup(value));
    },
    setPopupContent: (value) => {
      dispatch(appActions.setPopupContent(value));
    },
    fetchWebsiteBanner: () => {
      dispatch(appActions.fetchWebsiteBanner());
    },
    setShowTermModal: (value, url) => { dispatch(appActions.setShowTermModal(value, url)) },
  };
}
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, mapDispatchToProps)(SBnewFooter);
